const CaretUp = ({ ...props }) => {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.3536 14.6464C18.5488 14.8417 18.5488 15.1583 18.3536 15.3535L17.6464 16.0606C17.4512 16.2559 17.1346 16.2559 16.9393 16.0606L12 11.1213L7.06066 16.0606C6.8654 16.2559 6.54882 16.2559 6.35355 16.0606L5.64645 15.3535C5.45119 15.1583 5.45119 14.8417 5.64645 14.6464L11.6464 8.64643C11.8417 8.45117 12.1583 8.45117 12.3536 8.64643L18.3536 14.6464Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default CaretUp
